<template>
  <el-button type="text"  @click="removeRoleById(row)">取消订单</el-button>
</template>

<script>
  import {cancelOrderRequest} from 'network/order'

  export default {
    name: "OrderCancel",
    props: {
      row: {
        type: Object,
        required: true
      },
    },
    methods: {
      // 根据ID删除用户
      removeRoleById(row) {
        const h = this.$createElement
        this.$confirm( '', {
          message:h('div',null, [
            h('i',{ class:'el-icon-warning',style:'color:#f90;font-size:30px;' }),
            h('span',{ style:'margin-left:10px;font-size:16px;line-height:30px;font-weight:600;vertical-align:top;'}, '您确定是否取消已经审核通过的订单'),
            h('p',{ style:'margin:10px 0 0 40px;' },'取消订单后无法恢复')
          ]),
          confirmButtonText: '取消订单',
          cancelButtonText: '暂不取消',
          // type: 'warning'
        }).then(() => {
          console.log(row);
            cancelOrderRequest({
              id: row.orderId
            }).then(res => {
            let result = res.data;
            if (result.code !== 200) {
              return this.alertMessage(result.message, 'error');
            }
            this.alertMessage('已取消', 'success');
            this.$emit('plan-list');
          });
          }).catch(() => {
          
        });
      }
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
